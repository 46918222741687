import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { AdmBtn, AdmBtnItem, AdminWrap, InputWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import AdminTablePart from '../../common/part/AdminTablePart';
import { ADM_ORDER_LIST } from '../../data/table';
import { useNavigate, useParams } from 'react-router';
import AdmOrderListItem from '../item/AdmOrderListItem';
import { apiAdmGetOrderList, apiAdmSetOrderDel, ORDER_STATUS, ORDER_TYPE } from '../../../../api/api_adm_order';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { momentFormat, nowTime } from '../../../../utils/format/time';
import * as XLSX from 'xlsx';

export default function AdmOrderPage() {
    const navigate = useNavigate();
    const adminUser = useAdminUser();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const {page} = useParams(); 
    const [titleTotalReload,setTitleTotalReload] = useState(false);
    const [search,setSearch] = useState({input:'',res:''});

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getList();
    },[adminUser.admin_id,page,search.res])

    const getList = async() =>{
        const res = await apiAdmGetOrderList(adminUser.admin_token,adminUser.admin_id,page ?? 1,15, search.res);        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }
    
    // 검색
    const searchAction = (e:any) =>{
        e.preventDefault();
        setPage(1);
        setSearch((obj:any)=>({...obj,['res']:obj.input}))
    }

    // 페이지 이동
    const setPage = (p:any) =>{
        navigate(`/adm/order/${p ?? 1}`);
    }

    // 상세
    const update = (data:any)=>{
        navigate(`/adm/orderUpdate/${data.order_code}`)
    }

    // 삭제
    const del = async(data:any)=>{
        if(window.confirm('주문을 삭제 하시겠습니까?')){
            const res = await apiAdmSetOrderDel(adminUser.admin_token,adminUser.admin_id,data.order_code);
            if(res.result){
                alert('주문을 삭제 했습니다.');
                getList();
                setTitleTotalReload(!titleTotalReload);
            }else{
                alert(res.error);
            }
        }
    }

    const xlsxDown = async() =>{
        const res = await apiAdmGetOrderList(adminUser.admin_token,adminUser.admin_id,1,999999999, '');
        if(res.result){
            if(res.data.length === 0) return;
            const newList = res.data.map((data:any)=>({
                '번호': data.order_id,
                '주문유형': data.category_name,
                '통신사': data.company_name,
                '상품코드': data.bundle_code,
                '고객유형': ORDER_TYPE[data.order_orderer_type],
                '고객명': data.order_orderer_name,
                '연락처': data.order_orderer_phone_number,
                '처리상태': ORDER_STATUS[data.order_status],
                '주문시간': momentFormat('YYYY-MM-DD hh:mm',data.order_timestamp),
                '처리시간': momentFormat('YYYY-MM-DD hh:mm',data.order_process_timestamp),
                '고객요청': data.order_request_comment,
                '추천코드': data.order_refer_code,
            }))
            const worksheet = XLSX.utils.json_to_sheet(newList);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const time = nowTime();
            // 파일 다운로드
            XLSX.writeFile(workbook, `internet_order_list_${time}.xlsx`);
        }
    }
    
    return (
        <Wrap>
            <AdminPageTotalPart title='전체 주문' part='order' reload={titleTotalReload}>
                <AdmBtn bg={'#000'} onClick={xlsxDown}>엑셀다운로드</AdmBtn>
            </AdminPageTotalPart>
            <AdminWrap>
                <TitleWrap>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>주문관리</BasicText>
                    <SearchWrap onSubmit={searchAction}>
                        <InputWrap>
                            <input value={search.input} onChange={(e:any)=>setSearch((obj:any)=>({...obj,['input']:e.target.value}))} placeholder='주문자 이름, 전화번호로 검색이 가능합니다'></input>
                        </InputWrap>
                        <AdmBtn type='submit' style={{flexShrink: '0'}} bg={'#000'}>검색</AdmBtn>
                    </SearchWrap>
                </TitleWrap>
                <TableWrap>
                    <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_ORDER_LIST} minWidth={1200} page={page ?? 1} total={total} setPage={setPage}>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            list.map((data:any,index:any)=>{
                                return(
                                    <AdmOrderListItem key={`partner-item-${index}`} data={data} update={()=>update(data)} del={()=>del(data)}/>
                                )
                            })
                        }
                    </AdminTablePart>
                </TableWrap>
            </AdminWrap>
        </Wrap>
    )
}
const Wrap = styled.div``;
const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const SearchWrap = styled.form`
    width: auto;
    display: flex;
    flex-shrink: 0;
    gap: 10px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        width: 100%;
    }
`;
const TableWrap = styled.div`
    margin-top: 20px;
`;