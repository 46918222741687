import React, { useState } from 'react'
import styled from 'styled-components'
import SelectItem from './SelectItem';
import { Row, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import BasicText from '../../../basic/BasicText';
import { FaCaretDown } from 'react-icons/fa';
import { numberWithComma } from '../../../../utils/format/number';
import SelectPaymentOpen from '../openItem/SelectPaymentOpen';

export default function SelectPaymentItem({list,action,network,nowUse}:any) {
    const [open,setOpen] = useState(false);

    const clickAction = () =>{
        setOpen(true)
    }

    const itemClick = (data:any) =>{
        action.set(data);
    }
    
    return (
        <SelectItem title='요금제'>
            <Wrap onClick={clickAction}>
                <SelectWrap>
                    <TextWrap>
                        <BasicText color='#333' fontSize={17} fontWeight={700}>{action.get?.plan_name}</BasicText>
                        <BasicText color='#777' fontSize={15}>{'|'}</BasicText>
                        <BasicText color='#777' fontSize={15}>월 <span style={{fontWeight:700}}>{numberWithComma(action.get?.plan_price)}</span>원</BasicText>
                    </TextWrap>
                    <FaCaretDown color='#474747'/>
                </SelectWrap>
                <ContentWrap>
                    <BasicText color='#777' fontSize={13}>데이터 <span>{action.get?.plan_desc_1}</span> / {action.get?.plan_desc_2} {action.get?.plan_desc_3}</BasicText>
                </ContentWrap>
            </Wrap>
            <SelectPaymentOpen open={open} setOpen={setOpen} list={list} itemClick={itemClick} network={network} nowUse={nowUse} />
        </SelectItem>
    )
}

const Wrap = styled.div`
    overflow: hidden;
    border-radius: ${addTheme.round}px;
    border: 1px solid #d3d3d3;
    width: 100%;
    max-width: 400px;
`;

const TextWrap = styled(Row)`
    gap: 6px;
    & span{
        color: #333;
    }
    cursor: pointer;
`;
const SelectWrap = styled(RowBetween)`
    height: 42px;
    cursor: pointer;
    padding: 0 12px;
    & svg {
        flex-shrink: 0;
    }
`;

const ContentWrap = styled(Row)`
    height: 42px;
    background-color: #f4f4f4;
    padding: 0 12px;
    & span{
        color:  #333;
    }
`;