import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import MyPageLoginPart from '../part/MyPageLoginPart';
import MyPagePart from '../part/MyPagePart';
import { useNavigate } from 'react-router';

export default function MyPage() {
    const [list,setList] = useState<any>([]);

    return (
        <MaxWidthWrap>
            <Wrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>인터넷 내역</BasicText>
                </TitleWrap>
                {list.length === 0 ? 
                    <MyPageLoginPart setList={setList} /> :
                    <MyPagePart list={list} />
                }
            </Wrap>
        </MaxWidthWrap>
    )
}


const Wrap = styled.div`
    width: 100%;
    margin: 0 auto;
`;

const TitleWrap = styled.div``;