import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { Checkbox, FormControlLabel, MenuItem, Select } from '@mui/material';
import SelectItem from '../../common/item/SelectItem';
import validator from 'validator';
import { fileTypeRes, onlyNumber } from '../../../utils/format/string';
import { apiGetInquiryCate, apiSetInquiry } from '../../../api/api_qna';

const INFO_INIT = {
    i_category_title: '',
    inquiry_answer_image: '',
    inquiry_id: '',
    inquiry_question: '',
    inquiry_user_name: '',
    inquiry_user_email: '',
    inquiry_user_phone_number: '',
    inquiry_answer: '',
    fileName: '',
    inquiry_question_image: '',
    questionFileName: '',
    inquiry_answer_timestamp: '0',
    inquiry_password: '',
}

export default function InquiryCreatePage() {
    const [chk,setChk] = useState(false);
    const chkRef = useRef<any>(null);
    const fileRef = useRef<any>(null);
    const [cate,setCate] = useState({
        "inquiry_category_id": 1,
        "inquiry_title": "구매문의"
    });
    const [cateList,setCateList] = useState<any>([]);
    const [info,setInfo] = useState(INFO_INIT);

    useEffect(()=>{
        getCate();
    },[]);

    const getCate = async() =>{
        const res = await apiGetInquiryCate(1,100);
        if(res.result){
            setCateList(res.data);
            setCate(res.data[0]);
        }
    }

    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({...obj,[name]:val}));
    }

    const cateChange = (val:any) =>{
        const item = cateList.filter((data:any)=>data.inquiry_category_id == val);
        if(item.length === 0) return;
        setCate(item[0]);
    }

    const createAction = async(e:any) =>{
        e.preventDefault();
        if(!chk){
            alert('개인정보 수집 및 이용을 동의해주세요.');
            return;
        }
        if(info.inquiry_user_name === ''){
            alert('이름을 입력해주세요');
            return;
        }
        if(info.inquiry_user_phone_number === ''){
            alert('연락처를 입력해주세요.');
            return;
        }
        if(info.inquiry_password === ''){
            alert('비밀번호를 입력해주세요.');
            return;
        }
        
        const emailChk = validator.isEmail(info.inquiry_user_email);
        if(!emailChk){
            alert('이메일을 확인해주세요.');
            return;
        }
        const res = await apiSetInquiry(info,cate,info.inquiry_password);
        if(res.result){
            alert('문의를 등록했습니다.');
            location.reload();
        }else{
            alert(res.error);
        }
    }

    const imgUploadClick = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg' || fileFormat === 'gif'){
                const reader = new FileReader();
                setInfo((obj:any)=>({...obj,questionFileName:file.name}))
                reader.onload = () => {
                    const result = reader.result;
                    setInfo((obj:any)=>({...obj,inquiry_question_image:result}));
                };

                reader.readAsDataURL(file);
            }else{
                alert('확장자가 png, jpg인 이미지 파일만 가능합니다.');
                fileRef.current.value = null;
            }
        }
    }

    return (
            <MaxWidthWrap>
                <Wrap>
                    <TitleWrap>
                        <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>1:1 문의</BasicText>
                        <BasicText fontSize={20} mFontSize={15}  align='center' marginTop={1}>궁금하신것이 있나요? 언제든지 문의해주세요! (카톡으로 문의 주시면 보다 더 빠르게 답변 가능합니다.)</BasicText>
                    </TitleWrap>
                    <form onSubmit={createAction}>
                        <ContentWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>작성자 <span>*</span></BasicText>
                                <ItemInputWrap>
                                    <input value={info.inquiry_user_name} onChange={(e:any) => valueChange('inquiry_user_name',e.target.value)} required placeholder='이름을 입력해주세요.'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>연락처 <span>*</span></BasicText>
                                <ItemInputWrap>
                                    <input value={info.inquiry_user_phone_number} onChange={(e:any) => valueChange('inquiry_user_phone_number',onlyNumber(e))} required placeholder='연락처를 입력해주세요'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>이메일 <span>*</span></BasicText>
                                <ItemInputWrap>
                                    <input value={info.inquiry_user_email} onChange={(e:any)=> valueChange('inquiry_user_email',e.target.value)} required placeholder='이메일을 입력해주세요.'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>비밀번호 <span>*</span></BasicText>
                                <ItemInputWrap>
                                    <input type='password' value={info.inquiry_password} onChange={(e:any)=> valueChange('inquiry_password',e.target.value)} required placeholder='비밀번호를 입력해주세요.'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText spColor='red'>카테고리 <span>*</span></BasicText>
                                <ItemInputWrap style={{padding:0}}>
                                    {cateList === undefined || cateList.length === 0 ?
                                        <></> :
                                        <SelectItem value={cate.inquiry_category_id} setValue={(val:any)=>cateChange(val)} none='no' width='100%'>
                                            {cateList.map((data:any,index:any)=>{
                                                return(
                                                    <MenuItem key={`inquiry-cate-${index}`} value={data.inquiry_category_id}>{data.inquiry_title}</MenuItem>
                                                )
                                            })}
                                        </SelectItem>
                                    }
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap align='start'>
                                <BasicText >문의내용</BasicText>
                                <div style={{width:'100%'}}>
                                    <ItemInputWrap>
                                        <textarea value={info.inquiry_question} onChange={(e:any) => valueChange('inquiry_question',e.target.value)} rows={8}></textarea>
                                    </ItemInputWrap>
                                    <ImgBtnWrap onClick={imgUploadClick}>이미지 업로드</ImgBtnWrap>
                                    <input ref={fileRef} type='file' style={{display:'none'}} accept='.png,.jpg' onChange={fileChange}></input>
                                    {info.inquiry_question_image === '' ?
                                        <></> :
                                        <FilePreviewWrap>
                                            <img src={info.inquiry_question_image} alt='file-img'></img>
                                        </FilePreviewWrap>
                                    }
                                </div>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText >개인정보 수집 및 이용 <span>*</span></BasicText>
                                {/* <label>
                                    <input ref={chkRef} type='checkbox' onChange={(e:any)=>setChk(e.target.checked)} required defaultChecked={chk}></input> 동의
                                </label> */}
                                <FormControlLabel required sx={{'& .MuiFormControlLabel-asterisk':{display:'none'}}} control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color},}} checked={chk} onChange={()=>setChk(!chk)} />} label="동의" />
                            </ItemWrap>
                        </ContentWrap>
                        <BtnWrap type='submit'>작성완료</BtnWrap>
                    </form>
                </Wrap>
            </MaxWidthWrap>
    )
}


const Wrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
`;
const TitleWrap = styled.div``;
const ContentWrap = styled(Row)`
    margin-top: 50px;
    align-items: start;
    flex-direction: column;
    gap: 15px;
`;

const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 185px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
    & input{
        width: 100%;
    }
    & textarea{
        width: 100%;
    }
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;

const FilePreviewWrap = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    &>img{
        width: 100%;
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.color};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin-top: 50px;
`;