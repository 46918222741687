import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, RowBetween } from '../../../../style/basic/commonStyle'
import { addTheme } from '../../../../style/addTheme';
import BasicText from '../../../basic/BasicText';
import { numberWithComma } from '../../../../utils/format/number';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiAdmGetTotalCount } from '../../../../api/api_adm_login';

export default function AdminPageTotalPart({title,children,part,reload}:any) {
    const adminUser = useAdminUser();
    const [count,setCount] = useState(0);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(part === '' || part === undefined) return;
        getCount();
    },[adminUser.admin_id,part,reload])

    const getCount = async() =>{
        const res = await apiAdmGetTotalCount(adminUser.admin_token,adminUser.admin_id,part);
        if(res.result){
            setCount(res.data.count);
        }
    }

    return (
        <Wrap>
            <TextWrap>
                <BasicText fontSize={18} fontWeight={700} mFontSize={16} color='#000'>{title}</BasicText>
                <BasicText fontSize={18} mFontSize={16} color='#000' spColor={addTheme.admBtn2}><span style={{fontWeight:700}}>{numberWithComma(count ?? 0)}</span> {part === 'sendPoint' ? '원' :'건'}</BasicText>
            </TextWrap>
            <BtnWrap>
                {children}
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled(RowBetween)`
    background-color: #fff;
    padding: 15px 20px;
    gap: 10px;
    border-radius: 15px;
    flex-wrap: wrap;
    min-height: 66px;
    @media ${addTheme.media.s}{
        padding: 10px;
    }
`;

const TextWrap = styled(Row)`
    gap: 20px;
    flex-wrap: wrap;
    width: auto;
`;

const BtnWrap = styled(Row)`
    gap: 8px;
    width: auto;
    flex-wrap: wrap;
`;