import { axiosConfigInstance } from "./config"

export const apiGetMyOrder = async(user_name:string,user_phone_number:string,user_password:string) =>{
    try {
        const res = await await axiosConfigInstance('/api/order').post('',{
            method: 'getMyOrderList',
            params: {
                user_name,user_phone_number,user_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 핸드폰 마이페이지 로그인
export const apiPhLogin = async(user_name:any,user_phone_number:any,user_password:any) =>{
    try {
        const res = await axiosConfigInstance('/api/order/phone').post('',{
            method: 'getAccountOrderToken',
            params:{
                user_name,user_phone_number,user_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 핸드폰 주문 리스트
export const apiGetPhOrder = async(order_token:string) =>{
    try {
        const res = await axiosConfigInstance('/api/order/phone').post('',{
            method: 'getOrderInfoListFromOrderToken',
            params:{
                order_token
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}