import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SelectItem from './SelectItem';
import { Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { FaCaretDown } from "react-icons/fa";
import { addTheme } from '../../../../style/addTheme';
import SelectCardOpen from '../openItem/SelectCardOpen';
import { numberWithComma } from '../../../../utils/format/number';

export default function SelectCardItem({list,action,nowUse}:any) {
    const [open,setOpen] = useState(false);
 
    const itemClick = (data:any) =>{
        action.set(data);
    }
    
    return (
        <SelectItem title='제휴카드'>
            <Wrap onClick={()=>setOpen(true)}>
                <ItemWrap>
                    <BasicText color='#777' fontSize={15}>{action.get?.card_name ?? '제휴카드 할인 혜택을 선택하세요.'}</BasicText>
                    <FaCaretDown color='#474747'/>
                </ItemWrap>
            </Wrap>
            {action.get?.item === undefined || action.get?.item.length === 0 ?
                <></> :
                <ResWrap>
                    <ResTitle>TIP</ResTitle>
                    <ResContent>해당카드를 전월실적 {action.get?.item.dis_card_require_price}만원 이상 사용시</ResContent>
                    <ResContent style={{width:'100%'}}><span>{action.get?.item.dis_card_month_count}개월 동안 총 {numberWithComma(action.get?.item.dis_card_month_count * action.get?.item.dis_card_month_price)}을 할인 </span> 받을 수 있습니다.</ResContent>
                </ResWrap>
            }
            <SelectCardOpen open={open} setOpen={setOpen} nowUse={nowUse} list={list} itemClick={itemClick} />
        </SelectItem>
    )
}

const Wrap = styled.div`
    max-width: 400px;
`;
const ItemWrap = styled(RowBetween)`
    border: 1px solid #d3d3d3;
    width: 100%;
    height: 42px;
    cursor: pointer;
    padding: 0 12px;
    border-radius: ${addTheme.round}px;
    & svg {
        flex-shrink: 0;
    }
`;


const ResWrap = styled(Row)`
    gap: 6px;
    font-size: 13px;
    margin-top: 8px;
    flex-wrap: wrap;
`;

const ResTitle = styled.div`
    color: #fff;
    background-color: #323232;
    padding: 4px 6px;
    border-radius: ${addTheme.round}px;
`;

const ResContent = styled.div`
    font-size: 13px;
    color: #333;
    & span{
        color: #074ca1;
    }
`;