export const NAME = [
    "이*수",
    "김*민",
    "박*영",
    "최*현",
    "정*우",
    "강*희",
    "조*나",
    "윤*정",
    "임*서",
    "한*솔",
    "오*민",
    "서*연",
    "신*아",
    "권*지",
    "허*라",
    "배*윤",
    "전*희",
    "홍*진",
    "류*준",
    "남*수",
    "차*영",
    "유*빈",
    "송*하",
    "민*영",
    "진*아",
    "구*리",
    "원*수",
    "변*희",
    "왕*하",
    "목*선",
    "길*영",
    "심*주",
    "탁*빈",
    "도*영",
    "황*미",
    "전*수",
    "차*희",
    "노*아",
    "진*수",
    "정*하",
    "남*영",
    "배*리",
    "목*현",
    "양*아",
    "하*경",
    "추*민",
    "김*하",
    "오*리",
    "서*미",
    "주*선",
    "정*빈",
    "윤*하",
    "신*영",
    "이*하"
]
export const CATE = [{key:'home',name:'홈'},{key:'store',name:'매장'},{key:'office',name:'오피스'}];

export const HOME = [
    "KT 인터넷","Skylife 인터넷",
];
export const STORE = [
    "KT 인터넷","KT 솔루션"
];
export const OFFICE = [
    "LG인터넷"
];
export const LIST_DATA:any = {
    "home": HOME,
    "store": STORE,
    "office": OFFICE,
}

export const PHONE_LIST:any = [
    '갤럭시 S24 울트라 256G','갤럭시 S24 256G','갤럭시 S24 256G플러스',
    '갤럭시 Z플립6 256G','갤럭시 Z폴드6 256G',
    '아이폰 16 PROMAX','아이폰 16 PRO','아이폰 16',
    '갤럭시 Z플립5 256G','갤럭시 Z폴드5 256G',
    '아이폰 15 PROMAX','아이폰 15 PRO','아이폰 15',
    '갤럭시 S23 울트라 256G','갤럭시 S23 256G','갤럭시 S23 256G플러스',
]