import React, { useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { onlyNumber } from '../../../utils/format/string';
import { apiPhLogin } from '../../../api/api_mypage';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../../store/common/user';

export default function MyPageNoPwPart({setLoginChk}:any) {
    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [pw,setPw] = useState('');
    const dispatch = useDispatch();
    const loginBtnAction = async(e:any) =>{
        e.preventDefault();
        const res = await apiPhLogin(name,phone,pw);
        if(res.result){
            setLoginChk(true);
            dispatch(setUserInfo({
                jwt_token: res.data.order_token,
            }))
        }else{
            alert(res.error);
        }
    }
    return (
        <Wrap>
            <TextWrap>
                <BasicText align='center' fontSize={16} color='#fff'>신청시 입력하신 정보로 주문 조회가 가능합니다.</BasicText>
            </TextWrap>
            <form onSubmit={loginBtnAction}>
                <InputWrap>
                    <BasicText color='#121212' fontSize={17}>명의자 성함</BasicText>
                    <input required value={name} onChange={(e:any)=>setName(e.target.value)} maxLength={20}></input>
                </InputWrap>
                <InputWrap>
                    <BasicText color='#121212' fontSize={17}>휴대폰 번호</BasicText>
                    <input required value={phone} onChange={(e:any)=>setPhone(onlyNumber(e))} maxLength={15}></input>
                </InputWrap>
                <InputWrap>
                    <BasicText color='#121212' fontSize={17}>비밀번호</BasicText>
                    <input type='password' required value={pw} onChange={(e:any)=>setPw(e.target.value)}></input>
                </InputWrap>
                <BtnWrap type='submit'>로그인</BtnWrap>
            </form>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
`;
const TextWrap = styled(Center)`
    margin-top: 30px;
    background-color: #3b3b3b;
    border-radius: 6px;
    padding: 14px;
`;

const InputWrap = styled(Row)`
    margin: 30px 0;
    flex-wrap: wrap;
    gap: 20px;
    &>p{
        flex-shrink: 0;
        width: 90px;
    }
    &>input{
        padding: 14px;
        border: 1px solid #e6e6e6;
        border-radius: 6px;
        width: calc(100% - 110px);
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.main};
    height: 52px;
    color: #fff;
    border-radius: 6px;
`;