import React from 'react'
import styled from 'styled-components'
import { Row } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText';

interface SelectItemType {
    title: string;
    children?: any;
}

export default function SelectItem({title,children}:SelectItemType) {
    return (
        <Wrap>
            <TitleWrap>
                <BasicText color='#222' fontSize={16}>{title}</BasicText>
            </TitleWrap>
            <ContentWrap>
                {children}
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-wrap: wrap;
    row-gap: 6px;
`;
const TitleWrap = styled.div`
    width: 120px;
    flex-shrink: 0;
`;
const ContentWrap = styled.div`
    width: 100%;
`;
