import React from 'react'
import styled, { css } from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';

export default function RightBtnPart() {
    const navigate = useNavigate();
    const mediaQuery = useMediaQuery(addTheme.media.s);
    const globalCompany = useGlobalCompanyInfo();
    const goInquiry = () =>{
        navigate('/benefitInquiry');
    }
    const phoneClick = () =>{
        if(globalCompany['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + globalCompany['config_company_call_number'];
    }
    const kakaoClick = () =>{
        if(globalCompany['config_social_kakao_link'] === undefined) return;
        window.open(globalCompany['config_social_kakao_link']);
    }
    
    return (
        <Wrap mediaQuery={mediaQuery}>
            <ItemWrap onClick={goInquiry}>
                <img alt='Inquiry' src='/img/asset/fnav01.png'></img>
            </ItemWrap>
            <ItemWrap onClick={phoneClick}>
                <img alt='Phone' src='/img/asset/fnav02.png'></img>
            </ItemWrap>
            <ItemWrap onClick={kakaoClick}>
                <img alt='kakao' src='/img/asset/fnav03.png'></img>
            </ItemWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)<{mediaQuery:boolean}>`
    position: fixed;
    right: 15px;
    flex-direction: column;
    gap: 8px;
    width: auto;
    z-index: 80;
    ${({mediaQuery}) => mediaQuery ? css`
        bottom: 10%;
    ` : css`
        top: 40%;
    `}
`;

const ItemWrap = styled(Center)`
    width: 46px;
    height: 46px;
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer;
    & img{
        width: 100%;
    }
`;