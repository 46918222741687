import React from 'react'
import styled from 'styled-components'
import RectItem from '../../../common/item/RectItem';

export default function PhoneReviewItem({data,itemClick}:any) {
    return (
        <Wrap onClick={itemClick}>
            <RectItem imgUrl={data?.review_image === undefined || data?.review_image === '' ? '/img/no.jpg' : data?.review_image}/>
        </Wrap>
    )
}

const Wrap = styled.div`
    & img{
        width: 100%;
    }
`;