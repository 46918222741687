interface TYPE_TABLE_TITLE {
    id:number;
    name:string;
    size:number;
    align:'left'|'center'|'right';
}

// 홈 문의
export const ADM_HOME_INQUIRY:TYPE_TABLE_TITLE[] = [
    {id:1,name:'문의유형',size:70,align:'left'},
    {id:2,name:'문의시간',size:30,align:'center'},
]

// 홈 주문
export const ADM_HOME_ORDER:TYPE_TABLE_TITLE[] = [
    {id:1,name:'주문번호',size:15,align:'center'},
    {id:2,name:'통신사',size:15,align:'center'},
    {id:3,name:'주문유형',size:15,align:'center'},
    {id:4,name:'처리상태',size:15,align:'center'},
    {id:5,name:'주문시간',size:20,align:'center'},
]

// 홈 리뷰
export const ADM_HOME_REVIEW:TYPE_TABLE_TITLE[] = [
    {id:1,name:'후기제목',size:40,align:'left'},
    {id:2,name:'후기별점',size:20,align:'center'},
    {id:3,name:'후기시간',size:20,align:'center'},
]

// 주문 리스트
export const ADM_ORDER_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '번호', size: 6, align: 'center'},
    {id: 2, name: '주문유형', size: 8, align: 'center'},
    {id: 3, name: '통신사', size: 8, align: 'center'},
    {id: 4, name: '상품코드', size: 9, align: 'center'},
    {id: 5, name: '고객유형', size: 8, align: 'center'},
    {id: 6, name: '고객명', size: 8, align: 'center'},
    {id: 7, name: '연락처', size: 9, align: 'center'},
    {id: 8, name: '처리상태', size: 10, align: 'center'},
    {id: 9, name: '주문시간', size: 10, align: 'center'},
    {id: 10, name: '처리시간', size: 10, align: 'center'},
    {id: 11, name: '관리', size: 14, align: 'center'},
]

// 상품 리스트
export const ADM_PRODUCT_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '통신사', size: 6, align: 'center'},
    {id: 2, name: '상품 코드', size: 12, align: 'center'},
    {id: 3, name: '데이터 제목', size: 11, align: 'left'},
    {id: 4, name: '속도', size: 7, align: 'center'},
    {id: 5, name: '기본가', size: 8, align: 'center'},
    {id: 6, name: '결합가', size: 8, align: 'center'},
    {id: 7, name: '공유기', size: 8, align: 'center'},
    {id: 8, name: '등록 시간', size: 10, align: 'center'},
    {id: 9, name: '수정 시간', size: 10, align: 'center'},
    {id: 10, name: '활성화여부', size: 8, align: 'center'},
    {id: 11, name: '관리', size: 14, align: 'center'},
]

// 상품-tv 리스트
export const ADM_PRODUCT_TV_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '통신사', size: 8, align: 'center'},
    {id: 2, name: '상품 코드', size: 11, align: 'center'},
    {id: 3, name: '데이터 제목', size: 11, align: 'left'},
    {id: 4, name: '채널수', size: 6, align: 'center'},
    {id: 5, name: '기본가', size: 7, align: 'center'},
    {id: 6, name: '셋톱박스', size: 7, align: 'center'},
    {id: 7, name: '공유기', size: 14, align: 'center'},
    {id: 8, name: '등록 시간', size: 9, align: 'center'},
    {id: 9, name: '수정 시간', size: 9, align: 'center'},
    {id: 10, name: '활성화여부', size: 6, align: 'center'},
    {id: 11, name: '관리', size: 14, align: 'center'},
]

// 상품-전화 리스트
export const ADM_PRODUCT_PHONE_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '통신사', size: 8, align: 'center'},
    {id: 2, name: '상품 코드', size: 11, align: 'center'},
    {id: 3, name: '데이터 제목', size: 11, align: 'left'},
    {id: 4, name: '간단 설명', size: 12, align: 'center'},
    {id: 5, name: '자세한 설명', size: 12, align: 'center'},
    {id: 6, name: '기본가', size: 8, align: 'center'},
    {id: 7, name: '등록 시간', size: 9, align: 'center'},
    {id: 8, name: '수정 시간', size: 9, align: 'center'},
    {id: 9, name: '활성화여부', size: 6, align: 'center'},
    {id: 10, name: '관리', size: 14, align: 'center'},
]

// 상품-cctv 리스트
export const ADM_PRODUCT_CCTV_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '통신사', size: 10, align: 'center'},
    {id: 2, name: '상품 코드', size: 12, align: 'center'},
    {id: 3, name: '데이터 제목', size: 12, align: 'left'},
    {id: 4, name: '최소 수', size: 10, align: 'center'},
    {id: 5, name: '기본가(cctv수: 가격)', size: 16, align: 'center'},
    {id: 6, name: '등록 시간', size: 9, align: 'center'},
    {id: 7, name: '수정 시간', size: 9, align: 'center'},
    {id: 8, name: '활성화여부', size: 8, align: 'center'},
    {id: 9, name: '관리', size: 14, align: 'center'},
]

// 상품-상품관리
export const ADM_PRODUCT_BUNDLE_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '통신사', size: 8, align: 'center'},
    {id: 2, name: '상품 코드', size: 11, align: 'center'},
    {id: 3, name: '인터넷', size: 11, align: 'center'},
    {id: 4, name: 'TV', size: 9, align: 'center'},
    {id: 5, name: '전화', size: 11, align: 'center'},
    {id: 6, name: '사은품', size: 10, align: 'center'},
    {id: 7, name: '등록시간', size: 9, align: 'center'},
    {id: 8, name: '수정시간', size: 9, align: 'center'},
    {id: 9, name: '활성화여부', size: 10, align: 'center'},
    {id: 10, name: '관리', size: 12, align: 'center'},
]

// 파트너
export const ADM_PARTNER_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '파트너코드', size: 12, align: 'center'},
    {id: 2, name: '파트너 유형', size: 10, align: 'center'},
    {id: 3, name: '이름', size: 14, align: 'center'},
    {id: 4, name: '연락처', size: 13, align: 'center'},
    {id: 5, name: '상태', size: 10, align: 'center'},
    {id: 6, name: '신청시간', size: 10, align: 'center'},
    {id: 7, name: '처리시간', size: 10, align: 'center'},
    {id: 8, name: '관리', size: 21, align: 'center'},
]

// 파트너 상세 포인트리스트
export const ADM_PARTNER_DETAIL_POINT_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '유형', size: 17, align: 'center'},
    {id: 2, name: '금액', size: 26, align: 'center'},
    {id: 3, name: '상태', size: 17, align: 'center'},
    {id: 4, name: '신청일', size: 20, align: 'center'},
    {id: 5, name: '처리일', size: 20, align: 'center'},
]

// 포인트 - 전체
export const ADM_POINT_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '코드', size: 7, align: 'center'},
    {id: 2, name: '파트너 유형', size: 9, align: 'center'},
    {id: 3, name: '이름', size: 12, align: 'center'},
    {id: 4, name: '연락처', size: 12, align: 'center'},
    {id: 5, name: '보유 포인트', size: 11, align: 'center'},
    {id: 6, name: '출금 신청 포인트', size: 11, align: 'center'},
    {id: 7, name: '구매 건수', size: 10, align: 'center'},
    {id: 8, name: '신청시간', size: 9, align: 'center'},
    {id: 9, name: '처리시간', size: 9, align: 'center'},
    {id: 10, name: '관리', size: 10, align: 'center'},
]

// 포인트 - 출금
export const ADM_POINT_WITHDRAWAL_LIST:TYPE_TABLE_TITLE[] = [
    {id: 1, name: '번호', size: 10, align: 'center'},
    {id: 2, name: '파트너 유형', size: 12, align: 'center'},
    {id: 3, name: '이름', size: 16, align: 'center'},
    {id: 4, name: '출금 신청 포인트', size: 16, align: 'center'},
    {id: 5, name: '상태', size: 10, align: 'center'},
    {id: 6, name: '신청시간', size: 13, align: 'center'},
    {id: 7, name: '처리시간', size: 13, align: 'center'},
    {id: 8, name: '관리', size: 10, align: 'center'},
]

// 포인트 - 전송
export const ADM_POINT_SEND_LIST:TYPE_TABLE_TITLE[] =[
    {id: 1, name: '번호', size: 12, align: 'center'},
    {id: 2, name: '파트너 유형', size: 18, align: 'center'},
    {id: 3, name: '이름', size: 26, align: 'center'},
    {id: 4, name: '전송 포인트', size: 20, align: 'center'},
    {id: 5, name: '처리시간', size: 24, align: 'center'},
]

// 포인트 전송의 검색
export const ADM_POINT_SEND_SEARCH_LIST:TYPE_TABLE_TITLE[] =[
    {id: 1, name: '코드', size: 14, align: 'center'},
    {id: 2, name: '유형', size: 14, align: 'center'},
    {id: 3, name: '이름', size: 20, align: 'center'},
    {id: 4, name: '전화번호', size: 20, align: 'center'},
    {id: 5, name: '상태', size: 14, align: 'center'},
    {id: 6, name: '', size: 18, align: 'center'},
]


// 후기
export const ADM_REVIEW_LIST:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:10, align: 'center'},
    {id:2,name:'제목',size:30, align: 'left'},
    {id:3,name:'별점',size:14, align: 'center'},
    {id:4,name:'고객명',size:12, align: 'center'},
    {id:5,name:'작성시간',size:16, align: 'center'},
    {id:6,name:'관리',size:18, align: 'center'},
]

// 문의
export const ADM_INQUIRY_LIST:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:8, align: 'center'},
    {id:2,name:'문의유형',size:12, align: 'center'},
    {id:3,name:'작성자',size:14, align: 'center'},
    {id:4,name:'연락처',size:14, align: 'center'},
    {id:5,name:'답변상태',size:12, align: 'center'},
    {id:6,name:'작성시간',size:12, align: 'center'},
    {id:7,name:'답변시간',size:12, align: 'center'},
    {id:8,name:'관리',size:16, align: 'center'},
]

// faq 카테고리
export const ADM_FAQ_CATE: TYPE_TABLE_TITLE[] = [
    {id:1,name:'순서',size:15, align: 'center'},
    {id:2,name:'제목',size:35, align: 'center'},
    {id:3,name:'개수',size:20, align: 'center'},
    {id:4,name:'관리',size:30, align: 'center'},
]

// faq 리스트
export const ADM_FAQ_LIST:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:10, align: 'center'},
    {id:2,name:'카테고리',size:25, align: 'left'},
    {id:3,name:'제목',size:40, align: 'left'},
    {id:4,name:'관리',size:25, align: 'center'},
]

// 배너 리스트
export const ADM_BANNER_LIST:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:8, align: 'center'},
    {id:2,name:'카테고리',size:12, align: 'center'},
    {id:3,name:'제목',size:15, align: 'left'},
    {id:4,name:'PC 이미지',size:18, align: 'center'},
    {id:5,name:'모바일 이미지',size:15, align: 'center'},
    {id:6,name:'링크',size:16, align: 'left'},
    {id:7,name:'관리',size:16, align: 'center'},
]

// 팝업 리스트
export const ADM_POPUP_LIST:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:10, align: 'center'},
    {id:2,name:'제목',size:22, align: 'left'},
    {id:3,name:'이미지',size:22, align: 'center'},
    {id:4,name:'링크관리',size:20, align: 'left'},
    {id:5,name:'관리',size:20, align: 'center'},
]

// 핸드폰 상품
export const ADMIN_PRODUCT_LIST:TYPE_TABLE_TITLE[] = [
    {id:1,name:'번호',size:10, align: 'center'},
    {id:2,name:'브랜드',size:10, align: 'center'},
    {id:3,name:'모델명',size:14, align: 'center'},
    {id:4,name:'상품제목명',size:28, align: 'left'},
    {id:5,name:'출고가',size:14, align: 'center'},
    {id:6,name:'활성화여부',size:10, align: 'center'},
    {id:7,name:'관리',size:14, align: 'center'},
]

// 핸드폰 주문
export const ADMIN_ORDER_LIST:TYPE_TABLE_TITLE[] =[
    {id:1,name:'번호',size:6, align: 'center'},
    {id:2,name:'통신사',size:8, align: 'center'},
    {id:3,name:'주문유형',size:10, align: 'center'},
    {id:4,name:'모델명',size:12, align: 'center'},
    {id:5,name:'고객명',size:8, align: 'center'},
    {id:6,name:'연락처',size:12, align: 'center'},
    {id:7,name:'처리상태',size:12, align: 'center'},
    {id:8,name:'주문시간',size:10, align: 'center'},
    {id:9,name:'처리시간',size:10, align: 'center'},
    {id:10,name:'관리',size:12, align: 'center'},
]

// 홈 핸드폰 주문
export const ADMIN_HOME_PH_ORDER:TYPE_TABLE_TITLE[] = [
    {id:1,name:'주문번호',size:15,align:'left'},
    {id:2,name:'모델명',size:20,align:'left'},
    {id:3,name:'변경통신사',size:15,align:'center'},
    {id:4,name:'구매유형',size:15,align:'center'},
    {id:5,name:'주문상태',size:15,align:'center'},
    {id:6,name:'주문시간',size:20,align:'center'},
]
