import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, ContentWrap, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { onlyNumber } from '../../../utils/format/string';
import { Checkbox, FormControlLabel } from '@mui/material';
import { apiGetBenefitInquiryCate, apiSetBenefitInquiry } from '../../../api/api_main';

const INFO_INIT = {
    user_name: '',
    user_phone_number: '',
    inquiry_question: '',
    product_category_code: '',
}

export default function BenefitInquiryPage() {
    const [chk,setChk] = useState(false);
    const [cateList,setCateList] = useState([]);
    const [info,setInfo] = useState({
        user_name: '',
        user_phone_number: '',
        inquiry_question: '',
        product_category_code: '',
    })

    useEffect(()=>{
        getCateList();
    },[])

     // 카테고리 리스트 가져오기
    const getCateList = async() =>{
        const res = await apiGetBenefitInquiryCate(1,100);
        if(res.result){
            setCateList(res.data);
        }
    }

    // input value 변경
    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({...obj,[name]:val}));
    }

    const createAction = async(e:any) =>{
        e.preventDefault();
        if(!chk){
            alert('개인정보 수집 및 이용을 동의해주세요.');
            return;
        }
        if(info.user_name === '' ){
            alert('이름을 입력해주세요.');
            return;
        }
        if(info.user_phone_number === ''){
            alert('휴대폰 번호를 입력해주세요.');
            return;
        }
        if(info.product_category_code === ''){
            alert('상품을 선택해주세요.')
            return;
        }
        const res = await apiSetBenefitInquiry(info);
        if(res.result){
            alert('신청되었습니다.');
            setInfo(INFO_INIT);
            setChk(false);
        }
    }
    
    return (
        <MaxWidthWrap>
            <Wrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={24} fontWeight={700} align='center' ls={1.5}>혜택 지원금 문의</BasicText>
                    <TitleSubItem>
                        <BasicText color='#fff' align='center'>신청시 입력하신 정보로 상담이 진행됩니다.</BasicText>
                    </TitleSubItem>
                </TitleWrap>
                <form onSubmit={createAction}>
                    <ContentWrap gap={20}>
                        <ItemWrap>
                            <BasicText spColor='#000'>고객 성함 <span>*</span></BasicText>
                            <ItemInputWrap>
                                <input value={info.user_name} onChange={(e:any) => valueChange('user_name',e.target.value)} required placeholder='이름을 입력해주세요.'></input>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText spColor='#000'>휴대폰 번호 <span>*</span></BasicText>
                            <ItemInputWrap>
                                <input value={info.user_phone_number} onChange={(e:any) => valueChange('user_phone_number',onlyNumber(e))} required placeholder='연락처(-없이)'></input>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText spColor='#000'>희망 상품 <span>*</span></BasicText>
                            <ChkWrap>
                                {cateList === undefined || cateList.length === 0 ?
                                    <></> :
                                    cateList.map((data:any,index:any)=>{
                                        return(
                                            <FormControlLabel key={`chk-${index}`} control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color}}} checked={info.product_category_code === data.product_category_code} onChange={()=>valueChange('product_category_code',data.product_category_code)} /> } label={data.product_category_name} />
                                        )
                                    })
                                }
                            </ChkWrap>
                        </ItemWrap>
                        <ItemWrap align='start'>
                            <BasicText>요청 사항</BasicText>
                            <div style={{width:'100%'}}>
                                <ItemInputWrap>
                                    <textarea value={info.inquiry_question} onChange={(e:any) => valueChange('inquiry_question',e.target.value)} rows={6} maxLength={1000}></textarea>
                                </ItemInputWrap>
                            </div>
                        </ItemWrap>
                        <ItemWrap>
                            <FormControlLabel control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color}}} checked={chk} onChange={(e:any)=>setChk(e.target.checked)} /> } label={'개인정보 수집·이용에 동의합니다.'} />
                            {/* <label>
                                <input type='checkbox' } required></input> 동의
                            </label> */}
                        </ItemWrap>
                    </ContentWrap>
                    <BtnWrap type='submit'>문의하기</BtnWrap>
                </form>
            </Wrap>
        </MaxWidthWrap>
    )
}


const Wrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    & form{
        margin-top: 30px;
    }
`;
const TitleWrap = styled.div``;
const TitleSubItem = styled.div`
    background-color: #3b3b3b;
    padding: 10px 12px;
    border-radius: 5px;
    margin-top: 30px;
`;
const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 185px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
    & input{
        width: 100%;
    }
    & textarea{
        width: 100%;
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.color};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin-top: 50px;
`;

const ChkWrap = styled(Row)``;