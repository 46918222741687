import React, { useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import MyPageNoPwPart from '../part/MyPageNoPwPart';
import BasicText from '../../basic/BasicText';
import MyPagePart from '../part/MyPagePart';

export default function MyPagePh() {
    const [loginChk,setLoginChk] = useState(false);

    return (
        <MaxWidthWrap>
            <TitleWrap>
                <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>핸드폰 내역</BasicText>
            </TitleWrap>
            <Wrap>
                {loginChk ? 
                    <MyPagePart loginChk={loginChk} /> :
                    <MyPageNoPwPart setLoginChk={setLoginChk} />
                }
            </Wrap>
        </MaxWidthWrap>
    )
}

const Wrap = styled.div``;

const TitleWrap = styled.div``;