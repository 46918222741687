import React from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../../style/basic/commonStyle'
import SelectItem from './SelectItem';
import { addTheme } from '../../../../style/addTheme';

export default function SelectUseItem({list,action}:any) {
    const clickAction = (data:any) =>{
        action.setUse(data);
    }
    return (
        <SelectItem title='사용중인 통신사'>
            <Wrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return (
                            <ItemWrap key={`use-${index}`} onClick={()=>clickAction(data)} state={data.id === action.use.id}>
                                {data.img === '' ?
                                    <>{data.name}</> :
                                    <img src={data.img} alt={data.name} />
                                }
                            </ItemWrap>
                        )
                    })
                }
            </Wrap>
        </SelectItem>
    )
}

const Wrap = styled(Row)`
    gap: 12px;
    max-width: 400px;
`;
const ItemWrap = styled(Center)<{state:boolean}>`
    width: 25%;
    height: 54px;
    border: 1px solid ${({state}) => state ? '#000' : '#d3d3d3'};
    background-color: ${({state}) => state ? '#fff' : '#f4f4f4'};
    border-radius: ${addTheme.round}px;
    color: ${({state}) => state ? '#000' : '#777'};
    cursor: pointer;
`;
