import React, { useState } from 'react'
import styled from 'styled-components';
import { AdmBtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { FormControlLabel, Radio } from '@mui/material';
import { onlyNumber } from '../../../../utils/format/string';
import OpenPlacePart from '../../../applicationForm/part/OpenPlacePart';

export default function AdmOrderUpdateOrdererPart({info,valueChange}:any) {
    const [open,setOpen] = useState(false);
    const setPlace = (data:any) =>{
        if(data?.zonecode === undefined || data?.zonecode === '') return;
        valueChange('order_orderer','order_orderer_post_code',data.zonecode);
        valueChange('order_orderer','order_orderer_address',data.roadAddress);
    }
    return (
        <Wrap>
            <ContentWrap gap={15}>
                <ItemWrap pWidth={120}>
                    <BasicText>연락처</BasicText>
                    <InputWrap>
                        <input onChange={(e:any)=>valueChange('order_orderer','order_orderer_phone_number',e.target.value)} value={info?.order_orderer?.order_orderer_phone_number ?? ''} />
                    </InputWrap>
                </ItemWrap>
                <ItemWrap gap='20px'>
                    <ItemWrap pWidth={120}>
                        <BasicText>고객명</BasicText>
                        <InputWrap>
                            <input onChange={(e:any)=>valueChange('order_orderer','order_orderer_name',e.target.value)} value={info?.order_orderer?.order_orderer_name ?? ''}/>
                        </InputWrap>
                    </ItemWrap>
                    <ItemWrap pWidth={120}>
                        <BasicText>주민등록번호</BasicText>
                        <InputWrap>
                            <input onChange={(e:any)=>valueChange('order_orderer','order_orderer_resident_number',onlyNumber(e))} value={info?.order_orderer?.order_orderer_resident_number ?? ''} />
                        </InputWrap>
                    </ItemWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>고객유형</BasicText>
                    <Row>
                        <FormControlLabel control={<Radio name='user-type' value={'1'} checked={info?.order_orderer?.order_orderer_type === '1'} onChange={(e:any)=>valueChange('order_orderer','order_orderer_type',e.target.value)} />} label="개인" />
                        <FormControlLabel control={<Radio name='user-type' value={'2'} checked={info?.order_orderer?.order_orderer_type === '2'} onChange={(e:any)=>valueChange('order_orderer','order_orderer_type',e.target.value)} />} label="사업자" />
                        <FormControlLabel control={<Radio name='user-type' value={'3'} checked={info?.order_orderer?.order_orderer_type === '3'} onChange={(e:any)=>valueChange('order_orderer','order_orderer_type',e.target.value)} />} label="외국인" />
                    </Row>
                </ItemWrap>
                <ItemWrap gap='20px'>
                    <ItemWrap pWidth={120}>
                        <BasicText>추가연락처</BasicText>
                        <InputWrap>
                            <input onChange={(e:any)=>valueChange('order_orderer','order_orderer_add_phone_number',e.target.value)} value={info?.order_orderer?.order_orderer_add_phone_number ?? ''}/>
                        </InputWrap>
                    </ItemWrap>
                    <ItemWrap pWidth={120}>
                        <BasicText>이메일</BasicText>
                        <InputWrap>
                            <input onChange={(e:any)=>valueChange('order_orderer','order_orderer_email',e.target.value)} value={info?.order_orderer?.order_orderer_email ?? ''} />
                        </InputWrap>
                    </ItemWrap>
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText marginTop={1}>주소</BasicText>
                    <Row style={{flexDirection:'column',gap:'5px'}}>
                        <Row style={{gap:'8px'}}>
                            <InputWrap max='120px'>
                                <input readOnly value={info?.order_orderer?.order_orderer_post_code ?? ''}></input>
                            </InputWrap>
                            <AdmBtnItem bg='#000' onClick={()=>setOpen(true)}>우편번호검색</AdmBtnItem>
                        </Row>
                        <InputWrap>
                            <input readOnly value={info?.order_orderer?.order_orderer_address ?? ''}></input>
                        </InputWrap>
                        <InputWrap>
                            <input value={info?.order_orderer?.order_orderer_address_detail ?? ''} onChange={(e:any)=>valueChange('order_orderer','order_orderer_address_detail',e.target.value)}></input>
                        </InputWrap>
                    </Row>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>설치일</BasicText>
                    <InputWrap>
                        <input type='date' value={info?.order_basic?.order_eqiup_date ?? ''} onChange={(e:any)=>valueChange('order_basic','order_equip_date',e.target.value)} />
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText marginTop={1}>고객요청</BasicText>
                    <InputWrap>
                        <textarea rows={5} value={info?.order_basic?.order_request_comment ?? ''} onChange={(e:any)=>valueChange('order_basic','order_request_comment',e.target.value)} />
                    </InputWrap>
                </ItemWrap>
            </ContentWrap>
            <OpenPlacePart open={open} setOpen={setOpen} setPlace={setPlace} />
        </Wrap>
    )
}

const Wrap = styled.div``;