import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import RectItem from '../../../common/item/RectItem';
import BasicText from '../../../basic/BasicText';
import { FaCamera } from "react-icons/fa";
import ContentPageItem from './ContentPageItem';
import { apiGetReviewList } from '../../../../api/api_review';
import StarPart from '../../../common/part/StarPart';
import { momentFormat } from '../../../../utils/format/time';
import { useNavigate } from 'react-router';
import ReviewDetailPart from '../../../review/part/ReviewDetailPart';

const ListItem = ({data,itemClick}:any) =>{
    return(
        <ItemWrap onClick={itemClick}>
            <ImgWrap>
                <RectItem imgUrl={data.review_image === '' ? '/img/no.jpg' : data.review_image} />
            </ImgWrap>
            <LeftWrap>
                <TextWrap>
                    <TitleWrap>
                        <BasicText fontSize={17} mFontSize={16} style={{flexShrink:0}}>{data.phone_model}</BasicText>
                        <StarWrap>
                            <StarPart count={data.review_rate} />
                            {data.review_image !== '' &&
                                <FaCamera color='#777' />
                            }
                        </StarWrap>
                    </TitleWrap>
                    <RightWrap>
                        <BasicText fontSize={15}>{data.review_user_name}고객님 · {momentFormat('YYYY.MM.DD',data.review_timestamp)}</BasicText>
                    </RightWrap>
                </TextWrap>
                <ContentWrap>
                    <BasicText fontSize={15} color='#616161' line={2}>{data.review_content}</BasicText>
                </ContentWrap>
            </LeftWrap>
        </ItemWrap>
    )
}

export default function ContentBuyReviewItem() {
    const navigate = useNavigate();
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(1);
    const [list,setList] = useState([]);
    const [open,setOpen] = useState(false);
    const [item,setItem] = useState<any>();

    useEffect(()=>{
        getList();
    },[page])

    const getList = async() =>{
        const res = await apiGetReviewList(page,5);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const itemClick = (idx:any) =>{
        setItem(idx);
        setOpen(true);
    }

    const writeBtnClick = () =>{
        navigate('/reviewCreate');
    }

    return (
        <Wrap>
            <ListWrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <ListItem key={`review-${index}`} data={data} itemClick={()=>itemClick(index)} />
                        )
                    })
                }
            </ListWrap>
            <PageWrap>
                <ContentPageItem page={page} setPage={setPage} total={total} btnName='후기 작성' btnAction={writeBtnClick}/>
            </PageWrap>
            <ReviewDetailPart open={open} setOpen={setOpen} item={item} setItem={setItem} page={page ?? 1} count={5}/>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 12px;
`;

const ListWrap = styled(Row)`
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
`;

const ItemWrap = styled(Row)`
    padding: 15px;
    border-radius: 15px;
    gap: 8px;
    border: 1px solid #dcdcdc;
    align-items: start;
    cursor: pointer;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        padding: 10px;
    }
`;

const LeftWrap = styled.div`
    width: 100%;
`;
const ImgWrap = styled.div`
    width: 68px;
    border-radius: ${addTheme.round}px;
    overflow: hidden;
    flex-shrink: 0;
    @media ${addTheme.media.s}{
        margin: 0 auto;
        width: 100%;
        max-width: 180px;
    }
`;
const TextWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;
const TitleWrap = styled(Row)`
    gap: 6px;
    width: auto;
`;
const StarWrap = styled(Row)`
    width: auto;
    gap: 5px;
`;
const StarIconWrap = styled(Row)`
    width: auto;
`;
const ContentWrap = styled.div`
    margin-top: 5px;
`;

const RightWrap = styled(Row)`
    width: auto;
    gap: 5px;
    flex-shrink: 0;
`;

const PageWrap = styled.div`
    margin-top: 10px;
    width: 100%;
`;
