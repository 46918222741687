import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ContentPageItem from './ContentPageItem';
import { FaLock } from "react-icons/fa";
import ContentSecretOpen from '../openItem/ContentSecretOpen';
import { addTheme } from '../../../../style/addTheme';
import { apiGetInquiry } from '../../../../api/api_qna';
import { INQUIRY_STATE } from '../../../../data/state';
import { momentFormat } from '../../../../utils/format/time';
import { useNavigate } from 'react-router';

const ListItem = ({itemClick,data}:any) =>{
    return(
        <TableRow>
            <TableCell sx={{padding:'10px 12px'}} align='center'>{data.inquiry_id}</TableCell>
            <TableCell sx={{padding:'10px 12px'}} align='center'>
                <CategoryItem state={data.inquiry_status === '1'}>{INQUIRY_STATE[data.inquiry_status]}</CategoryItem>
            </TableCell>
            <TableCell sx={{padding:'10px 12px'}} align='left'>
                <ContentItem style={{cursor:'pointer'}} onClick={itemClick}>
                    <BasicText line={1} fontSize={12} color='#555'>{data.i_category_title}</BasicText>
                    <FaLock color='#c8c8c8' />
                </ContentItem>
            </TableCell>
            <TableCell sx={{padding:'10px 12px'}} align='center'>
                <ContentItem>
                    <BasicText fontSize={13}>{data.inquiry_user_name}</BasicText>
                </ContentItem>
            </TableCell>
            <TableCell sx={{padding:'10px 12px',color:'#555'}} align='center'>{momentFormat('YYYY/MM/DD',data.inquiry_question_timestamp)}</TableCell>
        </TableRow>
    )
}

export default function ContentInquiryItem() {
    const navigate = useNavigate();
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(1);
    const [list,setList] = useState([]);

    useEffect(()=>{
        getList();
    },[page])

    const getList = async() =>{
        const res = await apiGetInquiry(page,5);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const itemClick = (id:any) =>{
        if(id === undefined) return;
        navigate(`/inquiryDetail/${id}`);
    }

    const writeBtnClick = () =>{
        navigate('/inquiryCreate');
    }

    return (
        <Wrap>
            <ContentWrap>
                <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width:'8%',padding:'12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">번호</TableCell>
                                <TableCell sx={{width:'20%',padding:'12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">카테고리</TableCell>
                                <TableCell sx={{width:'43%',padding:'12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">제목</TableCell>
                                <TableCell sx={{width:'12%',padding:'12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">작성자</TableCell>
                                <TableCell sx={{width:'17%',padding:'12px',backgroundColor:'#f4f4f4',borderBottom:'#1px solid #dcdcdc',borderTop: '1px solid #dcdcdc'}} align="center">작성일</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <ListItem key={`inquiry-${index}`} itemClick={()=>itemClick(data.inquiry_id)} data={data} />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </ContentWrap>
            <PageWrap>
                <ContentPageItem page={page} setPage={setPage} total={total} btnName='문의 하기' btnAction={writeBtnClick}/>
            </PageWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 12px;
`;

const ContentWrap = styled.div`
    width: 100%;
`;
const CategoryItem = styled(Center)<{state:boolean}>`
    width: 100%;
    border: 1px solid ${({state}) => state ? addTheme.main : '#c8c8c8'};
    background-color: ${({state}) => state ? addTheme.main : '#fff'};
    color: ${({state}) => state ? '#fff' : '#555'};
    height: 38px;
    font-size: 13px;
`;
const ContentItem = styled(Row)`
    gap: 6px;
`;

const PageWrap = styled.div`
    margin-top: 10px;
    width: 100%;
`;
