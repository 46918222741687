import React from 'react'
import styled from 'styled-components';
import { Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import SwiperPart from '../../../common/part/SwiperPart';
import { SwiperSlide } from 'swiper/react';
import { addTheme } from '../../../../style/addTheme';
import { numberWithComma } from '../../../../utils/format/number';

const ListItem = ({data,itemClick}:any) =>{
    const detailClick = () =>{
        window.open(data.card_url_link);
    }
    return (
        <ItemWrap>
            <ImgWrap>
                <img alt='card-img' src={data.card_image}></img>
            </ImgWrap>
            <BasicText align='center' fontSize={18} marginTop={1}>{data.card_name}</BasicText>
            <BasicText align='center' fontSize={15} marginTop={1} color={'#999'}>고객센터 {data.card_call_number}</BasicText>
            <ItemLine />
            <ItemsWrap>
                {data.dis_card_list === undefined || data.dis_card_list.length === 0 ?
                    <></> :
                    data.dis_card_list.map((item:any,index:any)=>{
                        return(
                            <ItemsItemWrap key={`card-items-${index}`}>
                                <ItemsTitleWrap>
                                    <BasicText color='#999' fontSize={15}>전월실적 {item.dis_card_require_price} 이상</BasicText>
                                    <SelectBtn onClick={()=>itemClick(data,item)}>선택하기</SelectBtn>
                                </ItemsTitleWrap>
                                <BasicText color='#333'>매월 <span>{numberWithComma(item.dis_card_month_price)}</span>원 X {item.dis_card_month_count}개월 = 총 <span>{numberWithComma(item.dis_card_month_price * item.dis_card_month_count)}</span>원</BasicText>
                            </ItemsItemWrap>
                        )
                    })
                }
            </ItemsWrap>
            <ItemLine />
            <BtnWrap>
                <BtnItem onClick={detailClick}>자세히 보기</BtnItem>
            </BtnWrap>
        </ItemWrap>
    )
}

export default function SelectCardOpen({open,setOpen,nowUse,list,itemClick}:any) {
    const mediaQuery = useMediaQuery(addTheme.media.s);
    const close = () =>{
        setOpen(false);
    }

    const clickAction = (data:any,item:any) =>{
        const newData = {...data,item:item};
        itemClick(newData);
        close();
    }
    
    return (
        <Dialog
            open={open}
            // onClose={close}
            scroll={'paper'}
            sx={{
                '.MuiPaper-root':{maxWidth:'1092px',width:'100%',margin:'10px',padding: mediaQuery ? '0' :'0',backgroundColor:'#fafafa'}
            }}
        >   
            <DialogTitle sx={{padding:2}}>
                <TitleWrap>
                    <img alt='logo' src={nowUse?.img} />
                    <BasicText align='center' fontSize={28} mFontSize={24} fontWeight={700}>{nowUse?.name} 제휴카드</BasicText>
                    <TitleCloseItem><IoClose size={25} onClick={close} /></TitleCloseItem>
                </TitleWrap>
            </DialogTitle>
            <DialogContent sx={{padding:'30px'}}>
                <SwiperPart view={mediaQuery ? 1 : 3} navigation={true}>
                    {list === undefined || list.length === 0 ?
                        <></> :
                        list.map((data:any,index:any)=>{
                            return(
                                <SwiperSlide key={`card-item-${index}`} >
                                    <ListItem data={data} itemClick={clickAction}/>
                                </SwiperSlide>
                            )
                        })
                    }
                </SwiperPart>
                <TextWrap>
                    <BasicText fontSize={18} mFontSize={16}>※ 공시지원금(휴대폰 가격 할인) 선택시 186일 후 요금제 하향 가능</BasicText>
                    <BasicText fontSize={18} mFontSize={16}>※ 선택약정할인(통신 요금 25% 할인) 선택시 125일 후 요금제 하향 가능</BasicText>
                </TextWrap>
            </DialogContent>
        </Dialog>
    )
}

const TitleWrap = styled(Center)`
    gap: 15px;
`;

const TitleCloseItem = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    & svg{
        cursor: pointer;
    }
`;

const TextWrap = styled(Row)`
    flex-direction: column;
    align-items: stretch;
`;

const ItemWrap = styled.div`
    background-color: #fff;
    padding: 15px 8px;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
`;

const ImgWrap = styled.div`
    text-align: center;
    height: 165px;
    &>img{
        padding: 12px 35px 0;
        height: 100%;
        max-width: auto;
    }
`;

const ItemLine = styled.div`
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px dotted #dcdcdc;
`;

const ItemsWrap = styled(Row)`
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
    padding: 0 5px;
    min-height: 220px;
    @media ${addTheme.media.s}{
        min-height: auto;
    }
`;

const ItemsItemWrap = styled.div`
    & span{
        font-weight: 700;
    }
`;

const ItemsTitleWrap = styled(RowBetween)`
    padding-left: 10px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: 4px;
        height: 4px;
        background-color: #777;
        border-radius: 50%;
    }
`;

const SelectBtn = styled(Center)`
    cursor: pointer;
    background-color: #323232;
    color: #fff;
    font-size: 13px;
    padding: 5px;
    border-radius: ${addTheme.round}px;
`;

const BtnWrap = styled(Center)``;
const BtnItem = styled(Center)`
    width: 50%;
    border: 1px solid #777;
    border-radius: 30px;
    color: #777;
    cursor: pointer;
    text-align: center;
    height: 40px;
`;