import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Row } from '../../../../style/basic/commonStyle';
import { useLocation, useNavigate } from 'react-router';
import { ADM_MENU_LIST } from '../../../../data/menu';

export default function MenuPart() {
    const navigate = useNavigate();
    const location = useLocation();
    const [menuOnIdx,setMenuOnIdx] = useState(0);
    
    useEffect(()=>{
        if(location.pathname === '/adm'){
            setMenuOnIdx(0);
            return;
        }
        ADM_MENU_LIST.map((data:any,index:any)=>{
            const chk = location.pathname.includes(data.path);
            if(chk){
                setMenuOnIdx(index+1);
            }
        })
    },[location.pathname]);

    const GoHistory = React.useCallback((router: string,type?:string) => {
        if(type==='out'){
            window.open(router);
            return;
        }
        navigate(router);
    },[]);
    
    return (
        <Wrap>
            {ADM_MENU_LIST.map((data:any,index:any)=>{
                return(
                    <MenuWrap key={`adm-menu-${index}`} onClick={()=>GoHistory(data.router)} fontSize={18}>{data.name}</MenuWrap>
                )
            })}
            <BgWrap menuOnIdx={menuOnIdx} />
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;
const MenuWrap = styled(Row)<{fontSize?:number}>`
    position: relative;
    z-index: 4;
    padding-left: 30px;
    color: #fff;
    cursor: pointer;
    height: 48px;
    font-size: ${props=>props.fontSize === undefined ? 15 : props.fontSize}px;
`;

const BgWrap = styled.div<{menuOnIdx:number}>`
    position: absolute;
    top: ${({menuOnIdx}) => (menuOnIdx-1) * 48}px;
    left: 0;
    width: 6px;
    height: 48px;
    background-color: #fff;
    z-index: -1;
    display: ${({menuOnIdx}) => menuOnIdx === 0 ? 'none' : 'block'};
    transition: 0.2s;
`;