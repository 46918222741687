import { Pagination, useMediaQuery } from '@mui/material';
import React from 'react'
import { addTheme } from '../../../../style/addTheme';
import { Center, RowBetween } from '../../../../style/basic/commonStyle';
import styled from 'styled-components';

export default function ContentPageItem({total,page,setPage,btnState,btnItem,btnName,btnAction}:any) {
    const mediaQuery = useMediaQuery(addTheme.media.s);
    const btnClick = () =>{
        if(btnAction){
            btnAction();
        }
    }

    return (
        <PageWrap>
            {btnState === 'false' ? 
                <></> :
                <SpaceWrap></SpaceWrap>
            }
            {total === 0 ?
                <></> :
                <Pagination size={mediaQuery ? "small" : "medium"} variant="outlined" shape="rounded" page={typeof page === 'string' ? Number(page) : page} count={total} onChange={(e:any, page:any) => setPage(page)}/>
            }
            {btnState === 'false' ? 
                <></> :
                btnItem ?
                    btnItem :
                    <WriteBtnWrap onClick={()=>btnClick()}>{btnName}</WriteBtnWrap>
            }
        </PageWrap>
    )
}

const PageWrap = styled(RowBetween)`
    flex-wrap: wrap;
    width: auto;
    & .Mui-selected{
        background-color: #333 !important;
        color: #fff;
    }
    @media ${addTheme.media.s}{
        &>div:nth-of-type(1){
            display: none;
        }
        &>nav{
            width: 100%;
            margin: 12px auto 0;
            order: 2;
        }
        &>nav>ul{
            justify-content: center;
        }
        justify-content: end;
    }
`;

const SpaceWrap = styled(Center)`
    width: 85px;
    height: 38px;
`;

const WriteBtnWrap = styled(SpaceWrap)`
    cursor: pointer;
    background-color: #333;
    color: #fff;
    border-radius: ${addTheme.round}px;
    font-size: 15px;
    border: 1px solid #333;
    &:hover {
        background-color: #fff;
        color: #333;
    }
`;