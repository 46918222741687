import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IoMenu,IoClose } from "react-icons/io5";
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import { MENU_LIST } from '../../../data/menu';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';
import { useMediaQuery } from '@mui/material';

export default function MoMenuPart({menuState}:any) {
    const navigate = useNavigate();
    const mediaQuery = useMediaQuery(addTheme.media.m);
    const [open,setOpen] = useState(false);

    useEffect(()=>{
        if(!mediaQuery){
            document.body.style.overflow = 'auto';
            setOpen(false);
        }
    },[mediaQuery]);

    useEffect(()=>{
        if(open){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    },[open]);

    const menuClick = (router:string) =>{
        setOpen(false);
        navigate(router);
    }
    return (
        <Wrap>
            <IoMenu style={{cursor:'pointer'}} size={24} onClick={()=>setOpen(true)}/>
            {open &&
                <InnerWrap>
                    <InnerWrapBg />
                    <MenuWrap>
                        <CloseWrap>
                            <IoClose onClick={()=>setOpen(false)} color='#fff' size={34}></IoClose>
                        </CloseWrap>
                        <MenuItemWrap>
                            {MENU_LIST === undefined || MENU_LIST.length === 0 ?
                                <></> :
                                MENU_LIST.map((data:any,index:any)=>{
                                    if(data.id === 0) return;
                                    return(
                                        <MenuItem key={`m-menu-${index}`} onClick={()=>menuClick(data.router)} menuState={index === menuState}>{data.name}</MenuItem>
                                    )
                                })
                            }
                        </MenuItemWrap>
                    </MenuWrap>
                </InnerWrap>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;

const InnerWrap = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 9999;
`;

const InnerWrapBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
`;

const MenuWrap = styled(RowBetween)`
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    flex-direction: column;
    padding: 30px;
    align-items: start;
`;

const CloseWrap = styled.div`
    position: absolute;
    top: 20px;
    left: -40px;
    cursor: pointer;
`;

const MenuItemWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 15px;
`;

const MenuItem = styled.div<{menuState:boolean}>`
    cursor: pointer;
    font-size: 18px;
    color: ${({menuState}) => menuState ? addTheme.main : '#000'};
    position: relative;
    padding: 10px 0;
`;
